import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import ConstellationPageTemplate from "../constellation/constellation";

const ConstellationObjectPage = ({ data }) => {
  return <ConstellationPageTemplate activeObject={data?.craftAPI?.entry} />;
};

export const constellationQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        ... on CraftAPI_constellations_constellationObject_Entry {
          id
          constellationComponentList {
            ...ConstellationComponentListFragment
          }
          coverImageFull: coverImage {
            ...ImageMetadataFragment
          }
          parent {
            uri
            ... on CraftAPI_constellations_constellationGroup_Entry {
              tag(label: true)
              title
              constellationImage {
                ... on CraftAPI_image_Asset {
                  ...ImageMetadataOnlyFragment
                  url(transform: "constellation")
                  imageFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              objects: children {
                ...ConstellationChildObjectsFragment
              }
            }
          }
          shortDescription
          descriptionHeader
          title
          uri
          tag(label: true)
          date
          text
        }
      }
    }
  }
`;

ConstellationObjectPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
};

export default ConstellationObjectPage;
